import qs from 'qs'
import request from '@/utils/request'
// 抖音发布视频
export function publish(data) {
    // 发布视频
    return request({
        url: '/api/app/createVideo/createVideo',
        methods: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'POST',
        data: qs.stringify(data)
    })
}
// 快手发布视频 /api/app/kwaiopenapi/kwaiUploadVideo
export function publishKs(data) {
    // 发布视频
    return request({
        url: '/api/app/kwaiopenapi/kwaiUploadVideo',
        methods: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'POST',
        data: qs.stringify(data)
    })
}
// 视频任务视频列表
export function getPublishList(data) {
    console.log(data);
    return request({
        url: '/api/app/createVideo/getVideoByGroupId',
        methods: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: data
    })
}
// 获得抖音发布链接
export function getPublishLink(data) {
    return request({
        url: '/api/app/createVideo/getReleaseH5ByReleaseId',
        methods: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: data
    })
}

// 获取视频任务列表
export function getPublishTaskList(data) {
    return request({
        url: '/api/app/videorelease/getReleaseTask',
        methods: 'GET',
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
        params: data
    })
}
// 获取视频任务用户发布列表
export function getPublishUserList(data) {
    return request({
        url: '/api/app/videorelease/getVideoListByGroupId',
        methods: 'GET',
        params: data
    })
}
// 通过关键字获得用户列表
export function searchUser(data) {
    return request({
        url: '/api/app/createVideo/searchUser',
        methods: 'GET',
        params: data
    })
}

// 获得话题列表
export function searchTag(data) {
    return request({
        url: '/api/app/createVideo/searchTag',
        methods: 'GET',
        params: data
    })
}
// 获得POI信息
export function searchPoi(data) {
    return request({
        url: '/api/app/createVideo/searchPoiInfo',
        methods: 'GET',
        params: data
    })
}
// 获得小程序列表
export function searchApp(data) {
    return request({
        url: '/api/app/createVideo/searchApplt',
        methods: 'GET',
        params: data
    })
}